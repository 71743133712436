import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';

const routes: Routes = [
  { path: '', loadChildren: () => import('./pages/loginpage/loginpage.module').then(m => m.LoginPageModule) },
  { path: 'confirmation', loadChildren: () => import('./pages/confirmation-page/confirmationpage.module').then(m => m.ConfirmationPageModule) },
  { path: 'tpp-home', loadChildren: () => import('./pages/tpppage/tpppage.module').then(m => m.TppPageModule) },
  { path: 'tpp-popup-consult', loadChildren: () => import('./pages/tpp-consult-page/tpp-consult-page.module').then(m => m.TppConsultPageModule) },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
