import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PreloadedCaptchaAPIService } from 'src/app/services/preloaded-captcha/preloaded-captcha-api.service';

@Component({
  selector: 'app-oba-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  isBellowWidth: boolean;
  isExpanded = false;
  loadAPI: Promise<any>;

  constructor(public translate: TranslateService,
    private loader: PreloadedCaptchaAPIService) { }

  ngOnInit() {
    this.isBellowWidth = (window.innerWidth <= 500);
  }

  onResize(event) {
    this.isBellowWidth = (window.innerWidth <= 500);
  }

  expandDropdown() {
    this.isExpanded = !this.isExpanded;
  }

  changeRecaptchaLanguage(language) {
    this.loader.updateLanguage(language);
  }
}
