import { Injectable } from '@angular/core';
import { SessionData } from '../../domain/session-data';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SessionDataService {

  private _sessionData: SessionData;

  public setSessionData(value: SessionData) {
    this._sessionData = value;
    sessionStorage.setItem('sessionData', JSON.stringify(value));
    return of(this._sessionData);
  }


  getSessionData(): Observable<SessionData> {
    if (this._sessionData) {
      return of(this._sessionData);
    } else {
      return of(JSON.parse(sessionStorage.getItem('sessionData')));
    }
  }

  getSessionDataObject(): SessionData {
    if (this._sessionData) {
      return this._sessionData;
    } else {
      return JSON.parse(sessionStorage.getItem('sessionData'));
    }
  }
}
