export const TRF_SEPA = 'SEPA';
export const TRF_SWIFT = 'SWIFT';
export const PAG_SERVICOS = 'PGS';
export const LOTE_TRF = 'LOTE-TRF';
export const LOTE = 'LOTE';
export const SEG_SOCIAL = 'SGS';
export const TSU = 'TSU';
export const TRF = 'TRF';

export const TYPE_TRF_SEPA = '001';
export const TYPE_TRF_SWIFT = '002';
export const TYPE_TRF_IPS = '003';
export const TYPE_TRF_TARGET = '004';

export const TYPE_TRF_SEPA_PLAN = '009';
export const TYPE_TRF_SEPA_SCHEDULE = '005';
export const TYPE_TRF_IPS_PLAN = '011';
export const TYPE_TRF_IPS_SCHEDULE = '007';
export const TYPE_TRF_SEPA_URG = '013';

export const TYPE_PAG_SERVICOS = '020';
export const TYPE_PAG_CARREGAMENTOS = '021';
export const TYPE_PAG_STATE = '022';

export const TYPE_PAG_SERVICOS_AGENDADOS = '023';
export const TYPE_PAG_CARREGAMENTOS_AGENDADOS = '024';
export const TYPE_PAG_STATE_AGENDADOS = '025';

export const TYPE_SEG_SOCIAL = '029';
export const TYPE_SEG_SOCIAL_SCHEDULE = '030';

export const TYPE_CANCEL_SEPA = 'C05';
export const TYPE_CANCEL_TARGET = 'C04';
export const TYPE_CANCEL_IPS = 'C07';

export const TYPE_CANCEL_SEPA_PERIODIC = 'C09';
export const TYPE_CANCEL_IPS_PERIODIC = 'C11';

export const TYPE_CANCEL_SERVICOS = 'C23';
export const TYPE_CANCEL_CARREGAMENTOS = 'C24';
export const TYPE_CANCEL_ESTADO = 'C25';

export const TYPE_CANCEL_SEG_SOCIAL_SCHEDULE = 'C30';

export const TYPE_LOTE_SEPA = '040';
export const TYPE_CANCEL_LOTE_SEPA = 'C40';

export const TYPE_LOTE_SWIFT = '041';
export const TYPE_CANCEL_LOTE_SWIFT = 'C41';

export const TYPE_LOTE_IPS = '042';
export const TYPE_CANCEL_LOTE_IPS = 'C42';

export const TYPE_LOTE_TARGET = '043';
export const TYPE_CANCEL_LOTE_TARGET = 'C43';

export const TYPE_PAYMENTS_TSU = '032';
export const TYPE_PAYMENTS_FUTURE_TSU = '033';
export const CANCEL_PAYMENTS_TSU = 'C33';


// Cancelamentos Transferências Pontuais
export const TYPE_CANCEL_SEPA_PONTUAL = 'C01';
export const TYPE_CANCEL_SWIFT_PONTUAL = 'C02';
export const TYPE_CANCEL_IPS_PONTUAL = 'C03';
export const TYPE_CANCEL_TARGET_PONTUAL = 'C13';

// Cancelamentos Pagamentos Pontuais
export const TYPE_CANCEL_PAG_SERVICOS_PONTUAL = 'C20';
export const TYPE_CANCEL_PAG_ESTADO_PONTUAL = 'C22';
export const TYPE_CANCEL_CARREGAMENTOS_PONTUAL = 'C21';
export const TYPE_CANCEL_TSU_PONTUAL = 'C32';

export const TYPE_LOTE_PAG_SERVICOS = '044';
export const TYPE_CANCEL_LOTE_PAG_SERVICOS = 'C44';

export const TYPE_LOTE_CARREGAMENTOS = '045';
export const TYPE_CANCEL_LOTE_CARREGAMENTOS = 'C45';

export const TYPE_LOTE_PAG_ESTADO = '046';
export const TYPE_CANCEL_LOTE_PAG_ESTADO = 'C46';

export const TYPE_LOTE_PAG_SS = '048';
export const TYPE_CANCEL_LOTE_PAG_SS = 'C48';

export const TYPE_LOTE_PAG_TSU = '047';
export const TYPE_CANCEL_LOTE_PAG_TSU = 'C47';
