import { Injectable } from '@angular/core';
import { UserData } from 'src/app/domain/user-data';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserDataService {

  private _userData: UserData;

  setUserData(value: UserData) {
    this._userData = value;
    sessionStorage.setItem('userData', JSON.stringify(value));
    return of(this._userData);
  }

  getUserData(): Observable<UserData> {
    if (!this._userData) {
      return of(JSON.parse(sessionStorage.getItem('userData')));
    } else {
      return of(this._userData);
    }
  }
}
