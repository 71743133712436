<footer id="sntndr-footer">
    <div class="container">
        <div class="footer-left-section">
             <div class="copyrights">
                {{'FOOTER.BANCO_SANTADER_COPYRIGHT' | translate}}
             </div>
        </div>

        <div class="footer-right-section">
            <div class="others-nav">
                <div class="contacts">
                    <a href="{{contactURL}}" target="_blank" rel="noopener noreferrer" title="Contacte-nos">{{'FOOTER.CONTACTE-NOS' | translate}}</a>
                </div>
            </div>
        </div>      
    </div>
</footer>