import { Injectable } from '@angular/core';
import { HttpHandler, HttpRequest, HttpXsrfTokenExtractor} from '@angular/common/http';
import { HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class XrsfInterceptor implements HttpInterceptor {

    constructor(private tokenExtractor: HttpXsrfTokenExtractor) { }


    intercept(req: HttpRequest<any>, next: HttpHandler): Observable <any> {
      const headerName = 'XSRF-TOKEN';
      const respHeaderName = 'X-XSRF-TOKEN';

      const token = this.tokenExtractor.getToken();
      if (token !== null && !req.headers.has(headerName)) {
        req = req.clone({ headers: req.headers.set(respHeaderName, token) });
      }
      return next.handle(req);
    }
}
