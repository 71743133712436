import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, Observer, of } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    jsonFile: any;
    constructor(private http: HttpClient) { }

    get(): Observable<any> {
        if (this.jsonFile) {
            return of(this.jsonFile);
        }
        return Observable.create((observer: Observer<any>) => {
            this.http.get('assets/env/url.config.json')
                .subscribe((arq) => {
                    this.jsonFile = arq;
                    observer.next(arq);
                    observer.complete();
                }, (error) => {
                    observer.error(error);
                    observer.complete();
                });
        });
    }

}
