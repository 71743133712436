<header (window:resize)="onResize($event)" id="sntndr-header">
    <div class="top-section">
        <div class="container">   
            <div class='open-banking-logo'>
                <img alt="Open Banking logo" src="assets/images/OpenBankingLogo.svg" width='100%' height='100%'>
            </div>
            <ul *ngIf="!isBellowWidth" class="languages inline" style="display:none">
                <li *ngFor="let lang of translate.getLangs()"
                    [class.selected]="lang === translate.currentLang"
                    (click)="translate.use(lang)">
                    <a class="language" (click)="changeRecaptchaLanguage('pt')" *ngIf="lang === 'pt';">PT</a>
                    <a class="language" (click)="changeRecaptchaLanguage('es')" *ngIf="lang === 'es';">ES</a>
                    <a class="language" (click)="changeRecaptchaLanguage('en')" *ngIf="lang === 'en';">EN</a>
                </li>
            </ul>
            <div *ngIf="isBellowWidth" (click)="expandDropdown()"  class="dropdown" style="display:none">
                <button [matMenuTriggerFor]="menu" class="dropbtn">{{translate.currentLang | uppercase}}</button>
                <div [className]="isExpanded ? 'drop-icon expanded': 'drop-icon'"></div>
                <mat-menu #menu="matMenu"(closed)="expandDropdown()" xPosition="after" yPosition="below" class="language-dropdown" style="min-width: 50px !important;">
                    <ul style="padding: 0">
                        <li class="language-selector" *ngFor="let lang of translate.getLangs()"
                            [class.selected]="lang === translate.currentLang"
                            (click)="translate.use(lang)">
                            <a class="language" (click)="changeRecaptchaLanguage('pt')" *ngIf="lang === 'pt';">PT</a>
                            <a class="language" (click)="changeRecaptchaLanguage('es')" *ngIf="lang === 'es';">ES</a>
                            <a class="language" (click)="changeRecaptchaLanguage('en')" *ngIf="lang === 'en';">EN</a>
                        </li>
                    </ul>
                </mat-menu>
            </div>         
        </div>
    </div>
</header>