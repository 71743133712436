<div class="error-page-container">
  <div class="error-page-main-container">
    <div class="error-page-icon"></div>
    <div class="error-page-message">
        <div class="error-page-message-title">{{feedBackTitle| translate}}</div>
        <div class="error-page-message-description" style="margin-top: 15px;">{{ feedBackMessage | translate:sessionData}}</div>
        <div class="error-page-message-description">{{feedBackMessagedDetail | translate}}</div>
      </div>
  </div>
</div>
