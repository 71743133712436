import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from 'src/app/shared/config';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer`
    })
  };

  constructor(private http: HttpClient,
    private configService: ConfigService) { }

  get<T>(url: string): Observable<T> {
    return this.configService.get()
      .pipe(
        switchMap((conf: any) => this.http.get<T>(`${conf.url_base}${url}`, this.httpOptions)),
        catchError(this.handleGenericError<T>())
      );
  }

  post<T>(url: string, body: any, agreementId: string): Observable<any> {
    return this.configService.get()
      .pipe(
        switchMap((conf: any) => this.http.post<T>(`${conf.url_base}${url}`, body, this.getHttpHeaders(agreementId))),
        catchError(this.handleGenericError<T>())
      );
  }

  private getHttpHeaders(agreementId: string) {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'charset': 'UTF-8',
        'agreementId': agreementId,
      })
    };
  }

  private handleGenericError<T>() {
    return (error: any): Observable<T> => {
      console.log(error);
      throw (error as T);
    };
  }
}
