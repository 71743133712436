import { Component, OnInit } from '@angular/core';
import { URL_CONTACTE_NOS } from 'src/app/shared/config-urls';

@Component({
  selector: 'app-oba-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  contactURL: string;

  constructor() { }

  ngOnInit() {
    this.contactURL = URL_CONTACTE_NOS;
  }

}
