import { Component, OnInit, enableProdMode, HostListener } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { BnNgIdleService } from 'bn-ng-idle';
import { RejectService } from './services/reject/reject.service';
import { SessionDataService } from './services/session-data/session-data.service';
import { PaymentBusinessService } from './services/payment-business/payment-business.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styles: []
})

export class AppComponent implements OnInit {
  title = 'open-banking-portal';
  translation: TranslateService;

  constructor(public translate: TranslateService,
    private bnIdle: BnNgIdleService,
    private rejectService: RejectService,
    private sessionDataService: SessionDataService,
    private paymentBusinessService: PaymentBusinessService,
    private router: Router,
    private route: ActivatedRoute) {
    this.translation = translate;
  }

  private unsubscribe: Subject<void> = new Subject();

  getTranslation(): TranslateService {
      return this.translation;
  }

  ngOnInit(): void {

    this.bnIdle.startWatching(300).subscribe((expired) => {
      if (expired) {
        this.verifySessionExpire();
      }
    });

    if (environment.production) {
      enableProdMode();
    }
    this.translation.addLangs(['pt', 'en', 'es']);

    const userLanguage = localStorage.getItem('language');
    if (userLanguage) {
      this.translation.setDefaultLang(userLanguage);
      this.translation.use(userLanguage);
    } else {
      this.translation.setDefaultLang('pt');
      this.translation.use('pt');
      localStorage.setItem('language', 'pt');
    }
  }

  onSelect(lang: string) {
    this.translation.use(lang);
    localStorage.setItem('language', lang);
  }

  verifySessionExpire() {
    this.sessionDataService.getSessionData().subscribe(
      (sessionData) => {
        if (sessionData.agreementType === 'A'
        || sessionData.agreementType === 'P' && !this.paymentBusinessService.isLoteOperation(sessionData.paymentType)) {
          this.rejectService.doExpire().subscribe(
            (_) => {
              const param = sessionData.entryParam;
              this.cleanSessionValues();

              if (sessionData.isApp && !sessionData.isConfirmed) {
                this.router.navigate(['error'], {relativeTo: this.route.pathFromRoot[0]});
              } else if (sessionData.agreementType === 'A' && !sessionData.isConfirmed) {
                this.router.navigate([`login/AISP/${param}`], {relativeTo: this.route.pathFromRoot[0]});
              } else if (sessionData.agreementType === 'P' && !sessionData.isConfirmed) {
                  this.router.navigate([`login/PISP/${param}`], {relativeTo: this.route.pathFromRoot[0]});
              }
            }
          );
        }
      }
    );
  }

  @HostListener('window:beforeunload')
  closeWindow() {
    this.registerWindowClose();
  }

  cleanSessionValues() {
    sessionStorage.removeItem('sessionData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('paymentAgreementData');
    sessionStorage.removeItem('accountAgreementData');
  }

  registerWindowClose() {
    this.rejectService.doClose().subscribe();
  }
}


