import { Injectable } from '@angular/core';
import { SessionDataService } from '../session-data/session-data.service';
import { RejectService } from '../reject/reject.service';
import { SessionData } from '../../domain/session-data';
import { takeUntil, switchMap } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RequestProviderService {

  constructor(private sessionDataService: SessionDataService, private rejectService: RejectService) { }

  private unsubscribe: Subject<void> = new Subject();

  goToProvider(sessionData: SessionData) {
    sessionData.isConfirmed = true;
    return this.sessionDataService.setSessionData(sessionData).
    pipe(takeUntil(this.unsubscribe), switchMap((_) => this.rejectService.doReject())).subscribe(
      (_) => window.location.href = sessionData.providerLink
    );
  }

  goToProviderWithSucess(sessionData: SessionData) {
    sessionData.isConfirmed = true;
    return this.sessionDataService.setSessionData(sessionData).
    pipe(takeUntil(this.unsubscribe), switchMap((_) => this.rejectService.doConclude())).subscribe(
      (_) => window.location.href = sessionData.providerLink
    );
  }
}
