import { Injectable } from '@angular/core';
import { HttpService } from '../http/http.service';
import { switchMap } from 'rxjs/operators';
import { SessionDataService } from '../session-data/session-data.service';
import { URL_REJECT, URL_CONCLUDE, URL_CLOSE } from 'src/app/shared/config-urls';
import { URL_EXPIRE } from 'src/app/shared/config-urls';
import { ConfigService } from '../../shared/config';

@Injectable({
  providedIn: 'root'
})
export class RejectService {

  constructor(private httpService: HttpService, private sessionService: SessionDataService, private configService: ConfigService) { }

  doReject() {
    return this.doInvokeUrl(`${URL_REJECT}`);
  }

  doConclude() {
    return this.doInvokeUrl(`${URL_CONCLUDE}`);
  }

  doExpire() {
    return this.doInvokeUrl(`${URL_EXPIRE}`);
  }

  doClose() {
    return this.doInvokeUrl(`${URL_CLOSE}`);
  }

  doInvokeUrl(url: string) {
    return this.sessionService.getSessionData()
      .pipe(switchMap(sessionData =>
        this.httpService.post<any>(
          url, {}, sessionData.agreementMap)));
  }
}
