import { Component, OnInit } from '@angular/core';
import { SessionDataService } from 'src/app/services/session-data/session-data.service';
import { takeUntil, switchMap } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { SessionData } from 'src/app/domain/session-data';
import { UserDataService } from 'src/app/services/user-data/user-data.service';
import { UserData } from 'src/app/domain/user-data';
import { RequestProviderService } from '../../services/request-provider/request-provider.service';


@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  sessionData: SessionData;
  feedBackTitle: string;
  feedBackMessage: string;
  feedBackMessagedDetail: string;

  userData: UserData;


  private unsubscribe: Subject<void> = new Subject();

  constructor(
    private sessionService: SessionDataService,
    private userDataService: UserDataService,
    private requestProviderService: RequestProviderService
  ) { }

  ngOnInit() {
    this.sessionService.getSessionData()
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(sessionData => of(this.storeSessionData(sessionData))),
        switchMap(() => this.userDataService.getUserData()),
        switchMap(userData => of(this.storeUserData(userData))),
        ).subscribe((_) => {
          this.setFeedbackMessage();
          this.cleanSessionValues();
          setTimeout(() => {
            this.goToProvider();
          }, 10000);
      }, () => this.setGenericFeedbackMessage());
  }


  setFeedbackMessage() {
    if ((this.sessionData.isApp || this.sessionData.requestExpired)
        && (this.sessionData.entityName && this.sessionData.providerLink && this.sessionData.agreementMap)) {
        this.feedBackTitle = 'ERROR.SESSAO_EXPIRADA';
        this.feedBackMessage = 'ERROR.SESSAO_EXPIRADA_MSG';
    } else {
      this.setGenericFeedbackMessage();
    }
  }

  setGenericFeedbackMessage() {
    this.feedBackTitle = 'ERROR.INDISPONIVEL';
    this.feedBackMessage = 'ERROR.NAO_POSSIVEL_APRESENTAR_PAGINA';
    this.feedBackMessagedDetail = 'ERROR.POR_FAVOR_TENTE_NOVAMENTE';
  }

  storeSessionData(sessionData: SessionData) {
    this.sessionData = sessionData;
    this.sessionData.isOnConfirmPage = true;
    return this.sessionData;
  }

  getSessionData() {
    return this.sessionData;
  }

  storeUserData(userData: UserData) {
    this.userData = userData;
    return this.userData;
  }

  goToProvider() {
    this.requestProviderService.goToProvider(this.sessionData);
  }

  cleanSessionValues() {
    sessionStorage.removeItem('sessionData');
    sessionStorage.removeItem('userData');
    sessionStorage.removeItem('paymentAgreementData');
    sessionStorage.removeItem('accountAgreementData');
  }

}
