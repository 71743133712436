import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PreloadedCaptchaAPIService {

  public updateLanguage(newLang: string): void {
    localStorage.setItem('language', newLang);
    const iframeGoogleCaptcha = document.getElementById('captcha-div');
    if (iframeGoogleCaptcha) {
      const iframe = iframeGoogleCaptcha.getElementsByTagName('iframe')[0];
      iframe.setAttribute('src', iframe.getAttribute('src').replace(/hl=(.*?)&/, `hl=${newLang}&`));
    }
  }

}
